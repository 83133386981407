import React, { useEffect, useState } from 'react';

function Plugins() {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(true);
  }, []);

  return (
    <section id="plugins" className={`plugins ${loaded ? 'fade-in' : ''}`}>
      <h2 className="section-title fade-in-delay-1">Our Plugins</h2>
      <div className="plugins-grid">
        <div className="plugin-card fade-in-delay-2" onClick={() => window.location.href='https://www.figma.com/community/plugin/1304874310414952650/mandala-maker'}>
          <img src={`${process.env.PUBLIC_URL}/Frame1.png`} alt="Mandala Maker Plugin" />
          <div className="plugin-card-content">
            <h3>Mandala Maker</h3>
            <p>Create intricate mandala designs with ease using our powerful Mandala Maker plugin.</p>
          </div>
        </div>
        <div className="plugin-card fade-in-delay-3" onClick={() => window.location.href='https://www.figma.com/community/plugin/1293975517470860904/circular-clone'}>
          <img src={`${process.env.PUBLIC_URL}/Frame2.png`} alt="Circular Clone Plugin" />
          <div className="plugin-card-content">
            <h3>Circular Clone</h3>
            <p>Effortlessly generate circular patterns and clones with our Circular Clone plugin.</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Plugins;
