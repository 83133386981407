import React, { useEffect, useState } from 'react';

function Hero() {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(true);
  }, []);

  return (
    <section id="home" className={`hero ${loaded ? 'fade-in' : ''}`}>
      <p>
        WE ARE <br /><span className="highlight">PXL8.Studio</span>
      </p>
    </section>
  );
}

export default Hero;
