import React, { useState, useEffect } from 'react';

function Header() {
  const [menuActive, setMenuActive] = useState(false);
  const [activeLink, setActiveLink] = useState('');

  const toggleMenu = () => {
    setMenuActive(!menuActive);
  };

  const closeMenu = () => {
    setMenuActive(false);
  };

  const handleOutsideClick = (e) => {
    if (!e.target.closest('.hamburger') && !e.target.closest('.mobile-menu')) {
      closeMenu();
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    document.addEventListener('click', handleOutsideClick);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  const handleScroll = () => {
    const sections = document.querySelectorAll('section');
    let current = '';
    sections.forEach((section) => {
      const sectionTop = section.offsetTop;
      const sectionHeight = section.clientHeight;
      if (window.pageYOffset >= sectionTop - sectionHeight / 3) {
        current = section.getAttribute('id');
      }
    });
    setActiveLink(current);
  };

  return (
    <header>
      <div className="logo">PXL8.Studio</div>
      <nav className="desktop-nav">
        <a href="#home" className={`nav-link ${activeLink === 'home' ? 'active' : ''}`}>Home</a>
        <a href="#about" className={`nav-link ${activeLink === 'about' ? 'active' : ''}`}>About</a>
        <a href="#services" className={`nav-link ${activeLink === 'services' ? 'active' : ''}`}>Services</a>
        <a href="#plugins" className={`nav-link ${activeLink === 'plugins' ? 'active' : ''}`}>Plugins</a>
        <a href="#contact" className={`nav-link ${activeLink === 'contact' ? 'active' : ''}`}>Contact</a>
      </nav>
      <div className="hamburger" onClick={toggleMenu}>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div className={`mobile-menu ${menuActive ? 'active' : ''}`}>
        <a href="#home" onClick={closeMenu}>Home</a>
        <a href="#about" onClick={closeMenu}>About</a>
        <a href="#services" onClick={closeMenu}>Services</a>
        <a href="#plugins" onClick={closeMenu}>Plugins</a>
        <a href="#contact" onClick={closeMenu}>Contact</a>
      </div>
    </header>
  );
}

export default Header;
