import React, { useEffect, useState } from 'react';

function Services() {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(true);
  }, []);

  return (
    <section id="services" className={`services ${loaded ? 'fade-in' : ''}`}>
      <h2 className="section-title">Services</h2>
      <div className="services-content">
        <div className="service-point">
          <strong>UI / UX:</strong><br /><br /> Craft visually appealing and user-friendly interfaces for mobile apps and web applications with our design expertise.
        </div>
        <div className="separator"></div>
        <div className="service-point">
          <strong>Mobile App & Web Development:</strong><br /><br /> Develop robust and scalable mobile and web applications that meet your business needs.
        </div>
        <div className="separator"></div>
        <div className="service-point">
          <strong>Shopify Store & App Development:</strong><br /><br /> Build and customize your online store with our expert Shopify development services.
        </div>
      </div>
    </section>
  );
}

export default Services;
