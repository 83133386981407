import React, { useState, useEffect } from 'react';
import Header from './components/Header';
import Hero from './components/Hero';
import About from './components/About';
import Services from './components/Services';
import Plugins from './components/Plugins';
import Contact from './components/Contact';
import Footer from './components/Footer';
import './App.css';

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000); // Simulate loading time
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <div className="App">
      {loading ? (
        <div className="loading">
          <div className="spinner"></div>
        </div>
      ) : (
        <>
          <Header />
          <Hero />
          <div className="separator"></div>
          <About />
          <div className="separator"></div>
          <Services />
          <div className="separator"></div>
          <Plugins />
          <div className="separator"></div>
          <Contact />
          <div className="separator"></div>
          <Footer />
        </>
      )}
    </div>
  );
}

export default App;
