import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Contact = () => {
    const [formData, setFormData] = useState({ name: '', email: '', message: '' });
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        const apiUrl = process.env.REACT_APP_AIRTABLE_API_URL;
        const baseId = process.env.REACT_APP_AIRTABLE_BASE_ID;
        const tableName = process.env.REACT_APP_AIRTABLE_TABLE_NAME;
        const accessToken = process.env.REACT_APP_AIRTABLE_ACCESS_TOKEN;

        try {
            const response = await fetch(`${apiUrl}/${baseId}/${tableName}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                },
                body: JSON.stringify({
                    fields: {
                        Name: formData.name,
                        Email: formData.email,
                        Message: formData.message,
                    }
                }),
            });

            if (response.ok) {
                setFormData({ name: '', email: '', message: '' });
                toast.success('Message sent successfully!');
            } else {
                const errorData = await response.json();
                throw new Error(errorData.error.message);
            }
        } catch (error) {
            toast.error('Sorry, something went wrong. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    return (
        <section id="contact" className="contact">
            <h2>LET'S WORK <br /><span className="highlight">TOGETHER</span></h2>
            <form className="contact-form" onSubmit={handleSubmit}>
                <input
                    type="text"
                    name="name"
                    placeholder="Your Name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                />
                <input
                    type="email"
                    name="email"
                    placeholder="Your Email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                />
                <textarea
                    name="message"
                    rows="4"
                    placeholder="Your Message"
                    value={formData.message}
                    onChange={handleChange}
                    required
                ></textarea>
                <button type="submit" disabled={isLoading}>
                    {isLoading ? 'Sending...' : 'Send Message'}
                </button>
            </form>
            <ToastContainer 
                position="top-center"
                autoClose={5000}
                hideProgressBar={true}
                closeOnClick
                pauseOnHover
                draggable
            />
        </section>
    );
};

export default Contact;
