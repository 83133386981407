import React from 'react';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-logo">PXL8.studio</div>
      <div className="footer-content">
        <div className="footer-nav">
          <a href="#home">Home</a>
          <a href="#about">About</a>
          <a href="#services">Services</a>
          <a href="#plugins">Plugins</a>
          <a href="#contact">Contact</a>
        </div>
        <div className="footer-social">
          <a href="https://x.com/PXL8_STUDIO">Twitter</a>
          <a href="https://www.instagram.com/pxl8.studio/">Instagram</a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
