import React, { useEffect, useState } from 'react';

function About() {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(true);
  }, []);

  return (
    <section id="about" className={`about ${loaded ? 'fade-in' : ''}`}>
      <h2 className="section-title">About</h2>
      <div className="about-content">
        <p>PXL8 is a creative design studio based in India, specializing in UI and mobile app designs. Our team has expertise in mobile app and web development, as well as Shopify store and app development. We bring together creativity and technical precision to create stunning digital experiences. Check out our portfolio to see our work.</p>
        <button><a href="#contact">CONTACT US</a></button>
      </div>
    </section>
  );
}

export default About;
